import SelectModeContainer from 'containers/SelectModeContainer';

const SelectMode = () => {
  return (
    <>
      <SelectModeContainer />
    </>
  );
};

export default SelectMode;
