import ViewTimesContainer from 'containers/ViewTimesContainer';

const ViewTimes = () => {
  return (
    <>
      <ViewTimesContainer />
    </>
  );
};

export default ViewTimes;
